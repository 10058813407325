import React from 'react'
import { AppFooter } from '../../Modules'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  return (
<div class="container">
        <h1>Privacy Policy</h1>
        <h2>General</h2>
        <p>XaPrice is a product/service that helps consumers make better commerce decisions. We help consumers with price comparison, offers, insights on the right time to purchase something, and exclusive offers.</p>
        <p>The aim is to help users save money, help them make money, save them time, and reduce their commerce friction. The data of the user stored in any kind would only be used for the betterment of customer experience and providing better business services at XaPrice . Your data would never be sold to any third party.</p>

        <h2>Personal Information</h2>
        <p>The information we learn from customers helps us personalise and continually improve your utilisation of XaPrice's Services. We use the information to handle all types of activities in which XaPrice is involved, which mainly includes processing payments, communicating with you on any online and offline platforms about orders, products, services, and promotional offers, updating our records and generally maintaining your accounts with us, displaying content such as wish lists and customer reviews, and recommending merchandise and services that might be of interest to you. We also use this information to improve Services and platforms, prevent or detect fraud or abuses of our website, and enable third parties to carry out technical, logistical, or other functions on our behalf.</p>

        <h2>Types of Information We Gather</h2>
        <ul>
            <li><strong>Information You Give Us:</strong> We receive and store any information you enter on our website or give us in any other way. You can choose not to provide certain information, but then you might not be able to take advantage of many of our features. We use the information that you provide for such purposes as responding to your requests, customising Services for you, improving our stores, and communicating with you.</li>
            <li><strong>Automatic Information:</strong> We receive and store certain types of information whenever you interact with us. For example, like many websites, we use "cookies" and we obtain certain types of information when your web browser accesses XaPrice Services or advertisements and other content served by or on behalf of XaPrice Services on other websites, offline and online platforms.</li>
            <li><strong>E-mail Communications:</strong> To help us make e-mails more useful and interesting, we often receive a confirmation when you open an e-mail from XaPrice Services if your computer supports such capabilities. We also compare our customer list to lists received from other companies in an effort to avoid sending unnecessary messages to our customers. If you do not want to receive e-mail or other mail from us, please unsubscribe from our mailing list through the link provided in the mail.</li>
            <li><strong>Information from Other Sources:</strong> We might receive information about you from other sources and add it to our account information.</li>
        </ul>

        <h2>Using Cookies</h2>
        <p>Cookies are alphanumeric identifiers that we transfer to your computer's hard drive through your web browser to enable our systems to recognise your browser and to provide features such as offers, Recommended for You, personalised advertisements on other websites, and affiliations.</p>
        <p>The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, and how to disable cookies altogether. Additionally, you can disable or delete similar data used by browser add-ons, such as Flash cookies, by changing the add-on's settings or visiting the website of its manufacturer. However, because cookies allow you to take advantage of some of Services' essential features, we recommend that you leave them turned on. For instance, if you block or otherwise reject our cookies, you will not be able to get offers, best deals, recommendations, etc. If you do leave cookies turned on, be sure to sign off when you finish using a shared computer.</p>

        <h2>Does XaPrice Share the Information it Receives?</h2>
        <p>Information about our customers is an important part of our business and we are not in the business of selling it to others. XaPrice Services shares customer information only as described below and with partners and co-branding organisations and in other Services controls that are either subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice.</p>

        <h2>How Secure Is Information About Me?</h2>
        <p>We have kept all the personal information private as in server; we will access this information as per detailed in this privacy policy for the sole purpose of providing improved services.</p>
        <p>It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off when you finish using a shared computer. XaPrice will never ask for your password in e-mails that you receive unexpectedly or that you did not request. Please remember to log out of your account and to close your internet browser window when you leave the XaPrice site; this is especially important if you use a PC in public locations. At present, this is the safest way to ensure that no one has access to your personal information. We assume no liability for the abuse that happened due to these kinds of issues.</p>

        <h2>What About Third-Party Advertisers and Links to Other Websites?</h2>
        <p>Our site may include third-party advertising and links to other websites. We do not provide any personally identifiable customer information to these advertisers or third-party websites.</p>
        <p>These third-party websites and advertisers, or Internet advertising companies working on their behalf, sometimes use technology to send (or "serve") the advertisements that appear on our website directly to your browser. They automatically receive your IP address when this happens. They may also use cookies, JavaScript, web beacons (also known as action tags), and other technologies to measure the effectiveness of their ads and to personalise advertising content. We do not have access to or control over cookies or other features that they may use, and the information practices of these advertisers and third-party websites are not covered by this Privacy Notice. Please contact them directly for more information about their privacy practices. In addition, the Network Advertising Initiative offers useful information about Internet advertising companies (also called "ad networks" or "network advertisers"), including information about how to opt-out of their information collection.</p>
        <p>XaPrice also displays personalised third-party advertising based on personal information about customers. Although XaPrice does not provide any personal information to advertisers, advertisers (including ad-serving companies) may assume that users who interact with or click on a personalised advertisement meet the criteria used to personalise the ad (for example, users in South Africa who browsed for or bought classical music). If you do not want us to use personal information that we gather to personalise the third-party advertisements we display to you, please send your name, address, and email address to <a href="mailto:info@XaPrice.co.za">info@XaPrice.co.za</a> with complete concern. We will keep it for future transactions.</p>

        <h2>Business Uses of our Services</h2>
        <p>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify XaPrice and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs, and attorneys' fees.</p>

        <h2>What Information Can I Access?</h2>
        <p>Services of XaPrice give you access to a broad range of information about your account and your interactions with Services for the limited purpose of viewing and, in certain cases, updating that information. Click <a href="#">here</a> to see some examples. This list will change as our website evolves.</p>

        <h2>Data Protection Guideline Changes</h2>
        <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We'll post notice of modifications to these terms on this page. We'll post notice of modified additional terms in the applicable Service. Changes will not apply immediately and will become effective no sooner than fifteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.</p>

        <h2>When Information is Accessed from Mobile Apps</h2>
        <p>We include some third-party SDKs which might also collect some personally identifiable information (PII) about you. This may include but is not restricted to your email ID, phone number, IP address, Android ID, IMEI, etc.</p>
    <AppFooter />
    </div>
  )
}

export default PrivacyPolicy
