import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ProductService } from "../../Service/ProductSerivice";
import "./Promotional.css";
import GridProducts from "../Shared/GridProducts";

// Component For promotional products
export default function Promotional(props) {
  const [onPromotionProducts, setOnPromotionProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [promotionText, setPromotionText] = useState(null);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(24); // Initial page size

  const containerRef = useRef(null);
  const title = document.getElementById("app-title");
  
  useEffect(() => {

    const fetchData = async () => {
      let fullSize = false;

      if (location.pathname === "/on-promotion") {
        setPromotionText("Promotions");
        title.innerHTML = "Promotions";

        fullSize = true;
      }

      if (fullSize) {
        try {
          // Get Summary of Promotions like in landing pages
          const onPromotions = await ProductService.getPromotionalProducts(
            currentPage,
            pageSize
          );

          setOnPromotionProducts((prevProducts) => [
            ...prevProducts,
            ...onPromotions,
          ]);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching promotional products", error);
          setIsLoading(false);
        }
      } else {
        // Get Summary of Promotions like in landing pages
        const onPromotions = await ProductService.getLatestPromotions();

        // Process these items and pick 10
        setOnPromotionProducts(onPromotions);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize]);

  // Load more items when scrolling to almost the bottom
  const handleScroll = () => {

    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const scrollPositionToBottom = scrollHeight - (scrollTop + window.innerHeight);
    const loadThreshold = 200; // Adjust this threshold as needed

    if (scrollPositionToBottom < loadThreshold) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if(!props.stopLazyload)
    {

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }
  }, []);

  return (
    <div ref={containerRef} className="scroll-container">
      <GridProducts
        isLoading={isLoading}
        header={promotionText || props?.header}
        icon={<i className="pi pi-tags text-400" style={{ fontSize: '1.3rem' }}></i>}
        spacing={props.spacing}
        viewAll={props.viewAll}
        items={onPromotionProducts}
      />
    </div>
  );
}
