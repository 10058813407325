import React, { useState } from "react";
import "./App.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-green/theme.css"; // I like
import { AppContext } from "./libs/contextLib";
import RoutesConfig from "./RoutesConfig";
import { Appbar } from "./Components/Shared/Layout/AppBar/Appbar";
import { BrowserRouter } from "react-router-dom";
//Using Favourites
// import "primereact/resources/themes/lara-light-teal/theme.css";
// import "primereact/resources/themes/saga-orange/theme.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SearchProduct,Promotional,Product,NotFound,Home, PriceDrop, Signup, ProductsAlerts,TermsAndConditions,AlcoholPromotion,PrivacyPolicy,Disclaimer,ContactUs,SupportedStores  } from "./Modules";



function App() {
  const contextValue = {
    // Define your context values here
  };
  const [spacing] = useState("1rem");
  return (
    <div className="App">
      <BrowserRouter>
        <Appbar spacing={spacing} />
        <Routes>
          <Route path="/search-product" element={<SearchProduct spacing={spacing} />} />

          <Route path="/" element={<Home spacing={spacing} />} />
        {/* <Route path="/search-product" element={<SearchProduct spacing={spacing} />} /> */}
        <Route path="/search-product" element={<></>} />
        <Route path="/on-promotion" element={<Promotional header={"Promotions"} spacing={spacing} />} />
        <Route path="/price-drop" element={<PriceDrop header={"Price Drop"} spacing={spacing} />} />
        <Route path="/product" element={<Product header={"Promotions"} spacing={spacing} />} />
        <Route path="/alcohol" element={<AlcoholPromotion header={"Alcohol Promotions"} spacing={spacing} />} />
        <Route path="/product-alerts" element={<ProductsAlerts header={"My Alerts"} spacing={spacing} />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions spacing={spacing}/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/supported-stores" element={<SupportedStores />}/>
        {/* <Route path="/sign-up" element={<Signup header={"Promotions"} spacing={spacing} />} /> */}
        <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
