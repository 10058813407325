import React, { useState, useEffect } from "react";
import { Avatar } from "primereact/avatar";
import "./GridProductItem.css";
import { Chip } from "primereact/chip";
import { useNavigate } from "react-router-dom";

export default function GridProductItem(props) {
  const navigate = useNavigate();

  const navigateTo = (url) => {
    navigate(url);
  };
  
  const DecodedText =(productItem) => {
    const encodedText = productItem;
  
    // Create a temporary element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = encodedText;
  
    // Get the decoded text
    const decodedText = tempElement.innerText;
  
    return (
        <span>{decodedText}</span>
    );
  }

  return (
    <div
      key={props.item.productCode}
      className="col-6 sm:col-4 md:col-3 lg:col-3 xl:col-2 p-1"
    >
      <div
        className="border-1 border-black-alpha-10"
        style={{ cursor: "pointer"}}
        onClick={(_) => navigateTo(`/product?code=${props.item.productCode}&navbar=${props.isNavBar? 1 : 0}`)}
      >
        <div className="flex flex-wrap align-items-center justify-content-center">
          {props.item.promoEndDays ?
            <Chip
              label={props.item.promoEndDays}
              icon="pi pi-clock"
              className="h-1.5rem mt-2 text-xs bg-secondary"
            />
        :
        // Comback here for Price Drop
        <Chip
              label={props.item.isPriceDrop ? 'Price Drop' : `No Promo`}
              // icon="pi pi-clock"
              className={`h-1.5rem mt-2 text-xs ${props.item.isPriceDrop? 'bg-primary': ''}`}
            />
            }
          {/* Image section */}
          <div className="pt-5 w-12rem border-round -mt-4">
            <div className="border-round-bottom p-3 flex align-items-center justify-content-center">
              <img
                className="w-9 sm:w-8rem xl:w-8rem  xl:block mx-auto border-round"
                style={{maxHeight:'120px'}}
                src={props.item.imageUrl}
                alt={props.item.name}
              />
            </div>
          </div>
        </div>
           <img
              className="w-4  flex align-items-left justify-content-left ml-2"
              src={props.item.partnerImageUrl}
              alt={props.item.name}
            />

        {/* Bottom section  */}
        <div className="product-content surface-overlay overflow-hidden">
          <div className="line-clamp-2 item-name">{DecodedText(props.item.name)}</div>
        </div>

        <div className="product-content item-rating">
          {props.item.promoPrice > 0 || props.item.isPriceDrop ? <span className="text-xs">
            <>
            {
              props.item.isPriceDrop ? <>
              <Avatar
              label={`${props.item.percentageSaved}%`}
              size="small"
              className={props.item.percentageSaved > 0 ? "bg-primary" : "bg-red-600"}
              style={{
                fontSize: ".75rem",
                fontWeight: "700",
                color: "#ffffff",
                height: "20px",
                marginRight: "3px",
                borderRadius: "2px",
              }}
            />
            (
            {props.item.saving}{" "}
            saving)
            </>
              :
              <>
            <Avatar
              label={`${props.item.percentageSaved}%`}
              size="small"
              className={props.item.percentageSaved > 0 ? "bg-primary" : "bg-red-600"}
              style={{
                fontSize: ".75rem",
                fontWeight: "700",
                color: "#ffffff",
                height: "20px",
                marginRight: "3px",
                borderRadius: "2px",
              }}
            />
            (
            {props.item.saving}{" "}
            saving)</>
            }
            </>

          </span>
          :
          
          <span className="text-xs">
          <Avatar
            label={`0%`}
            size="small"
            className="bg-default"
            style={{
              fontSize: ".75rem",
              fontWeight: "700",
              color: "#ffffff",
              height: "20px",
              marginRight: "3px",
              borderRadius: "2px",
            }}
          />
          (
          {"R0 "}
          saving)
        </span>
        }
        </div>

        <div className="product-content spacing">
          <div className="text-xs">
            {
            props.item.promoPrice > 0 || props.item.isPriceDrop ? 
            
            //Come back For price drop
            props.item.isPriceDrop ?
            <p className="text-lg font-bold text-black-alpha-90 mr-3">
              R{props.item.price}
              
              <del className="text-xs text-black-alpha-40" style={{marginLeft: ".5rem"}}>
                R{props.item.oldPrice}
              </del>
            </p>
            :       
            <p className="text-lg font-bold text-black-alpha-90 mr-3">
              R{props.item.promoPrice}
              <del className="text-xs text-black-alpha-40" style={{marginLeft: ".5rem"}}>
                R{props.item.price}
              </del>
            </p>
          :
            <p className="text-lg font-bold text-black-alpha-90 mr-3">
                R{props.item.price}
            </p>  

          }
          </div>
        </div>

      </div>

    </div>
  );
}