import React, { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ProductService } from "../../Service/ProductSerivice";

import GridProducts from "../Shared/GridProducts";

export default function SearchProduct(props) {
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();
  const SearchedProduct = decodeURIComponent(searchParams.get("q"));
  const isNavbar = decodeURIComponent(searchParams.get("navbar"));
  const isSimilar = searchParams.get("similar");
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(24); // Initial page size
  const containerRef = useRef(null);
  const title = document.getElementById("app-title");


  useEffect(() => {
    if(currentPage ==1)
       window.scrollTo(0, 0); 

    fetchData(currentPage, pageSize);

    title.innerHTML = (isNavbar === "true") ? "Category -  " + SearchedProduct : "Search -  " + SearchedProduct;
  }, [SearchedProduct,currentPage]);

  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const scrollPositionToBottom = scrollHeight - (scrollTop + window.innerHeight);
    const loadThreshold = 200; // Adjust this threshold as needed
  
    if (scrollPositionToBottom < loadThreshold && scrollPositionToBottom > 0) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [SearchedProduct,currentPage]);

  const fetchData = async (currentPage, pageSize) => {
    try {
      const nextPage = currentSearchTerm === SearchedProduct ? currentPage : 1;

      let data = [] 
      
      if(isSimilar){
        data = await ProductService.getSimilarProducts(SearchedProduct, nextPage, pageSize);
      }
      else{
        if(isNavbar === "true"){
            data = await ProductService.getLatestPromotionsByTag(SearchedProduct,nextPage, pageSize);

            data = {
              items : [...data]
            }
          }
        else
            data = await ProductService.getSearchedProduct(SearchedProduct, nextPage, pageSize);
      }
      
      if (currentSearchTerm === SearchedProduct) {
        // Append items if the search terms are equal
        setSearchedProducts((prevProducts) => [...prevProducts, ...data.items]);
      } else {
        // Add a new record if the search terms are different
        setSearchedProducts(data.items);
        setCurrentSearchTerm(SearchedProduct);
        setCurrentPage(nextPage); // Reset pagination
      }
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching searched products", error);
      setIsLoading(false);
    }
  };

  return (
    <div ref={containerRef} className="scroll-container">
    <GridProducts
      isNavbar={isNavbar}
      isLoading={isLoading}
      header={isSimilar ? "Similar items" : isNavbar == "true" ? "": "Product results for " + `"${SearchedProduct}"`}
      spacing={props.spacing}
      items={searchedProducts}
    />
    </div>
  );
}
