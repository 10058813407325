
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function ProductPriceHistoryChart(props) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: [...props.priceHistory.days],
            datasets: [
                // {
                //     label: 'Promotion Price',
                //     data: [65, 59, 80, 81, 56, 55, 40],
                //     fill: false,
                //     borderColor: documentStyle.getPropertyValue('--green-500'),
                //     tension: 0.4
                // },
                {
                    label: 'Price History',
                    data: [...props.priceHistory.prices],
                    fill: true,
                    tension: 0.4,
                    backgroundColor: [
                        'rgba(76, 175, 80, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)'
                      ],
                      borderColor: [
                        'rgb(76, 175, 80)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)'
                      ],
                      borderWidth: 1
                }
            ]
        };
        const options = {
            maintainAspectRatio: false,
            // aspectRatio: 0.6,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },

            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        // color: surfaceBorder,
                        color: 'rgba(232,232,232, 0.5)',
                    },
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        // color: 'rgba(255,255,255)',
                        color: 'rgba(232,232,232, 0.5)', //Original
                    },
                    beginAtZero: true
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card" style={{ margin: props.spacing, marginTop: '2rem'}}>
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    )
}
        