import React, { useRef, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { AuthenticationSerivice } from "../../Service/AuthenticationSerivice";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { useLocalStorage } from 'primereact/hooks';

export function Login(props) {
    const [visible, setVisible] = useState(props.show);
    const [codeVisible, setCodeVisible] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [email, setEmail] = useState(false);
    const [fullName, setFullName] = useState(false);
    const [code, setCode] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [user, setUser] = useLocalStorage(null, 'user');

    const toast = useRef(null);
    // const authenticationSerivice = AuthenticationSerivice();

    const sendVerificationCode =()=>{
        setIsloading(true)
        
        if(email.length > 4){

            //Register
            if(isRegister)
            {
                let user = {
                    email:email,
                    fullName:fullName
                }
                AuthenticationSerivice.registration(user).then(res=> {
                    setCodeVisible(true)
                    setIsloading(false)
    
                    toast.current.show({ severity: 'success',life:10000, summary: 'Email sent', detail: 'Code sent to ' + email });
                })
                .catch(ex =>{
                    setCodeVisible(false);
                    setIsloading(false)
                    toast.current.show({ severity: 'error',life:10000, summary: 'Error', detail: ex.message});
                });

                return;
            }
            //Login
            AuthenticationSerivice.login(email).then(res=> {
                setCodeVisible(true)
                setIsloading(false)

                toast.current.show({ severity: 'success',life:10000, summary: 'Email sent', detail: 'Code sent to ' + email });
            })
            .catch(ex =>{
                setCodeVisible(false);
                setIsloading(false)
                toast.current.show({ severity: 'error',life:10000, summary: 'Error', detail: ex.message});
            });
        }
        else
            setIsloading(false)
    }

    const submitCode =()=>{
        setIsloading(true)
        
        if(code.length == 5){
            AuthenticationSerivice.verifyCode(email,code).then(res=> {
                setIsloading(false);
                setVisible(false);
                props.updateProfile(res.fullName.split(' ')[0]);
                setUser(res);
                setCodeVisible(false);
                toast.current.show({ severity: 'success',life:5000, summary: 'Success', detail: 'Login Success' });
            })
            .catch(ex =>{
                setCodeVisible(true);
                setIsloading(false)
                toast.current.show({ severity: 'error',life:10000, summary: 'Error', detail: ex.message});
            });
        }
    }
    
  return (
    <div className="card flex justify-content-center">
    
    <Dialog
        visible={props.show}
        modal
        onHide={() => setVisible(props.showLogin)}
        content={({ hide }) => (
            <div className="flex flex-column px-8 py-5 gap-4" style={{  backgroundImage: 'radial-gradient(circle at left top, var(--primary-500), var(--primary-700))' }}>
                <Toast ref={toast} position="top-center"/>
                <img src="logo192.png" style={{color:'white'}} width="35" height="35" viewBox="0 0 35 35"  className="block mx-auto" />

                {
                    props.isLoggedIn ?
                    <Button label="Logout" style={{borderRadius:'20px'}} onClick={(e) => props.logout()} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>
                    :
                    <>
                <div className="inline-flex flex-column gap-2">
                { !codeVisible && isRegister && <><label htmlFor="fullname" className="text-primary-50 font-semibold">
                        Full Name
                    </label>
                    <InputText id="fullname" onChange={(e)=>setFullName(e.target.value)} label="Full Name" className="bg-white-alpha-20 border-none p-3 text-primary-50" type="text"></InputText>
                </>}

                    { !codeVisible && <><label htmlFor="username" className="text-primary-50 font-semibold">
                        Email
                    </label>
                    <InputText id="email" onChange={(e)=>setEmail(e.target.value)} label="Email" className="bg-white-alpha-20 border-none p-3 text-primary-50" type="email"></InputText>
                </>}
                
                   {
                      codeVisible && <> <label htmlFor="code" className="text-primary-50 font-semibold">
                            Code
                        </label>
                        <InputText id="number" label="code" onChange={(e)=>setCode(e.target.value)} className="bg-white-alpha-20 border-none p-3 text-primary-50" type="number"></InputText>
                        </>
                   } 

                </div>
                <div className="flex align-items-center gap-2">
                   {isLoading ?<ProgressSpinner />
                    : <Button size={'small'}  disabled={isRegister}  label={codeVisible ? "Submit" : "Login"} style={{borderRadius:'20px'}}  onClick={(e) => codeVisible ? submitCode() : sendVerificationCode()} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10">
                    </Button>
                    }
                    <Button label="Cancel" style={{borderRadius:'20px'}} onClick={(e) => {hide(e); setIsRegister(false) }} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>
                </div>
                <div className="flex align-items-center gap-2">
                {!isRegister ? <Button size={'small'}  style={{borderRadius:'20px'}} onClick={() => setIsRegister(!isRegister)} text label="Don't have account?" className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"/>
                :
                <Button size={'small'} label="Register" style={{borderRadius:'20px'}} onClick={(e) => codeVisible ? submitCode() : sendVerificationCode()} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>}
                </div>
                </>
                }
            </div>
        )}
    ></Dialog>
</div>

  );
}
