import axios from 'axios';
const prod = "https://api.xaprice.co.za";
const local = "https://localhost:5001";
const URL_XAPRICE = prod + "/api/Users";

const options = {
    headers: {
        'Content-Type': 'application/json'
    }
}

export const AuthenticationSerivice = {
    verifyCode(email,code){
        return axios.get(URL_XAPRICE + '/VerifyCode?email=' + email + "&code=" + code).then(res => res.data);
    },
    login(email){

        return axios.post(URL_XAPRICE + '/Login?email='+ email, options).then(res => res.data);
    },
    registration(user){
        return axios.post(URL_XAPRICE + '/UserRegistration',  user).then(res => res.data);
    },

    contactus(data){
        return axios.post(URL_XAPRICE + '/ContactUs',  data).then(res => res.data);
    },
};

