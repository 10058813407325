import React from 'react'
import Promotional from '../Promotional/Promotional'


function AlcoholPromotion(props) {
  return (
    <div style={{ margin: props.spacing }}>

      < Promotional spacing={props.spacing} isAlcohol={true} header={props.header}/>
    </div>
  )
}

export default AlcoholPromotion
