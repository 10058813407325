import { Button } from "primereact/button";
import React, {  } from "react";
import  {AppFooter, Products, }  from "../../Modules";
export function Home(props) {
  return (
    //Add More components to landing page
    <>
        <Products spacing={props.spacing} header={props.header}/>
        <AppFooter spacing={props.spacing} />
    </>
  );
}
