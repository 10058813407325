import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { ProductService } from "../../Service/ProductSerivice";
import "./ProductsAlerts.css";
import { Avatar } from "primereact/avatar";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { useLocation, useNavigate } from "react-router";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AppFooter } from "../../Modules";

export default function ProductsAlerts(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [showLogin, setShowLogin] = useState(false);
  const title = document.getElementById("app-title");
  const toastBottomCenter = useRef(null);
  const [isDefinately, setIsDefinately] = useState(false);
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);
  const [productAlerts, setProductAlerts] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);

    await ProductService.getAllProductAlert(user.id)
      .then((response) => {
        var alerts = [];
        response.forEach((element) => {
          const productDetails = { ...element.product };
          delete element.product;

          alerts.push({ ...element, ...productDetails });
        });

        console.log(alerts)
        setProductAlerts(alerts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading product alerts", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {

    title.innerHTML = "My Tracked Products";
    window.scrollTo(0, 0);

    fetchData();
  }, []); // Fetch data when product code changes

  const formatCurrency = (value) => {
    return value.toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' });
  };

  const goToPage =(e) =>{
    navigate(e);
  }

  const TrackProduct = (code) => {
    if (!user) {
      setShowLogin(true);
      return;
    }

    ProductService.SetProductAlert(code, user.id)
      .then((response) => {
        var list = productAlerts.filter(p => p.productCode !== code);
        setProductAlerts(list);
        showMessage(toastBottomCenter,"warn");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showMessage = (ref, severity) => {
    ref.current.show({ severity: severity, detail: "Product Untracked", life: 3000 });
  };

  const convertDate = (date) => {
    // const inputDateStr = "2024-04-21T23:59:59";

    const inputDate = new Date(date);
    const startDateStr = inputDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return startDateStr;
  };

  const isOutofStock = (date) => {
    const now = new Date();
    const diffMs = now - new Date(date);
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffMin / 60);

    if (diffHour > 8) {
      return false;
    } else return true;
  };

  const timeAgo = (date) => {
    const now = new Date();
    const diffMs = now - new Date(date);
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffMin / 60);
    const diffDay = Math.floor(diffHour / 24);

    if (diffSec < 60) {
      return `${diffSec} seconds ago`;
    } else if (diffMin < 60) {
      return `${diffMin} minutes ago`;
    } else if (diffHour < 24) {
      return `${diffHour} hours ago`;
    } else {
      return `${diffDay} days ago`;
    }
  };

  const DecodedText = (productItem) => {
    const encodedText = productItem;

    // Create a temporary element
    const tempElement = document.createElement("div");
    tempElement.innerHTML = encodedText;

    // Get the decoded text
    const decodedText = tempElement.innerText;

    return <span>{decodedText}</span>;
  };

  //TODO PRODUCTS
  
  const storeImageBodyTemplate = (product) => {
    return (
      <img
        src={product.partnerImageUrl}
        alt={product.name}
        className="w-5rem shadow-0 border-round"
        onClick={()=> goToPage(`/product?code=${product.productCode}`)}
        style={{cursor:'pointer'}}
      />
    );
  };

  const imageBodyTemplate = (product) => {
    return (
      <img
        src={product.imageUrl}
        alt={product.name}
        className="w-3rem shadow-0 border-round"
        onClick={()=> goToPage(`/product?code=${product.productCode}`)}
        style={{cursor:'pointer'}}
      />
    );
  };

  const priceBodyTemplate = (product) => {
    if(product.promoPrice > 0)
        return formatCurrency(product.price);
    else if(product.oldPrice > 0)
        return formatCurrency(product.oldPrice);
    else
      return formatCurrency(product.price);
  };

  const promoPriceBodyTemplate = (product) => {
    if(product.promoPrice > 0 || product.oldPrice > 0)
      return product.promoPrice > 0 ? formatCurrency(product.promoPrice) : formatCurrency(product.price);
    return  0
  }

  const percentageSavedBodyTemplate = (product) => {
    return  <> <Avatar
    label={`${product.percentageSaved}%`}
    size="small"
    className={product.percentageSaved > 0 ? "bg-primary" : "bg-red-600"}
    style={{
      fontSize: ".75rem",
      fontWeight: "700",
      color: "#ffffff",
      height: "20px",
      marginRight: "3px",
      borderRadius: "2px",
    }}
  /> </>
  }

  const statusBodyTemplate = (product) => {
    return (
      <Button label="Remove" onClick={ () => TrackProduct(product.productCode)} severity="danger" size="small" rounded />
      // <Button label="Remove" style={{borderRadius:'20px'}} onClick={(e) => props.logout()} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30"></Button>
    );
  };

  const nameBodyTemplate = (product) => {
    return DecodedText(product.name.length > 45 ? product.name?.substring(0,55) + '...' : product.name);
  }

  const footer = `You've tracked ${
    productAlerts ? productAlerts.length : 0
  } products`;

  return (
    <>
      {!isLoading ? (
        <>
        <div
          className="grid screen-margin card"
          style={{ margin: props.spacing }}
        >
         <Toast ref={toastBottomCenter} position="bottom-center" />
         <div className="flex flex-wrap align-items-center justify-content-between gap-2 mb-4 mt-3">
            <span className="text-xl text-900 font-bold">My Tracked Products</span>
          </div>
        <DataTable value={productAlerts} footer={footer} className="w-full">
         
         <Column header="Store" body={storeImageBodyTemplate}></Column>
         <Column header="Name" body={nameBodyTemplate}></Column>
          <Column header="Image" body={imageBodyTemplate}></Column>
          <Column field="price" header="Price"  body={priceBodyTemplate}></Column>
          <Column header="Promo Price" body={promoPriceBodyTemplate}></Column>
          <Column field="percentageSaved" sortable={true}  header="% Saved" body={percentageSavedBodyTemplate}></Column>
          <Column header="Action" body={statusBodyTemplate}></Column>
        </DataTable>

        </div>
          <AppFooter/>
        </>
      ) : (
        <div className="grid screen-margin" style={{ margin: props.spacing }}>
          {/* Left (Product) */}
          <div className="col-12 md:col-4 lg:col-4">
            <div className="flex flex-wrap align-items-center justify-content-center border-1 border-black-alpha-10">
              <div className="pt-5 w-12rem m-3 border-round">
                <div className="border-round-bottom p-3 flex align-items-center justify-content-center">
                  <Skeleton
                    height="15rem"
                    className="w-9 sm:w-16rem xl:w-10rem  xl:block mx-auto border-round fullsize-image"
                  ></Skeleton>
                </div>
              </div>
            </div>
          </div>

          {/* Right side (Product Details) */}
          <div className="col-12 md:col-8 lg:col-8">
            <div className="text-left p-0 ">
              <span className="text-400 surface-overlay font-medium text-xs">
                <Skeleton width="5rem" className="mb-2"></Skeleton>
              </span>
            </div>

            <div className="text-left p-0 ">
              <div className="text-2xl font-bold">
                <Skeleton width="10rem" className="mb-2"></Skeleton>
              </div>
            </div>

            <div className="text-left p-0 space">
              <Skeleton width="15rem" className="mb-2"></Skeleton>
            </div>

            <div className="text-left p-0 space">
              <Skeleton width="10rem" height="3rem" className="mb-2"></Skeleton>
            </div>

            <div className="text-center p-3 border-round-sm surface-50 font-bold">
              <div className="p-0 space">
                <Skeleton
                  width="100%"
                  height="5rem"
                  className="mb-2"
                ></Skeleton>
              </div>

              <div className="text-400 text-sm  text-left space">
                <Skeleton width="15rem" className="mb-2"></Skeleton>
              </div>
              <div className="text-400 text-sm  text-left space">
                <Skeleton
                  width="10rem"
                  height="2rem"
                  className="mb-2"
                ></Skeleton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
