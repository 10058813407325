import React, {useRef, useState} from 'react'
import { AppFooter } from '../../Modules'
import { AuthenticationSerivice } from "../../Service/AuthenticationSerivice";
import { Button } from 'primereact/button';
import './ContactUs.css'
import { Toast } from 'primereact/toast';
// import ContactUs
function ContactUs() {
    const [messageVisible, setMessageVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsloading] = useState(false);

    const toast = useRef(null);

    const sendEmail =()=>{
        setIsloading(true)
        
        var payload = {
            email,
            firstName,
            lastName: surname,
            message
        }

        console.log("Payload", payload);

        if(email.length > 4){
            //Login
            AuthenticationSerivice.contactus(payload).then(res=> {
                setMessageVisible(true)
                setIsloading(false)

                toast.current.show({ severity: 'success',life:10000, summary: 'Email sent', detail: 'Thanks for contacting us' + email });

                // Clear data 
                setFirstName('');
                setSurname('');
                setMessage('');
                setEmail('');
            })
            .catch(ex =>{
                setMessageVisible(false);
                setIsloading(false);
                toast.current.show({ severity: 'error',life:10000, summary: 'Error', detail:"Email could not be sent try again"});
                setFirstName('');
                setSurname('');
                setMessage('');
                setEmail('');
            });
        }
        else
            setIsloading(false);
    }

  return (
    <div>
        <Toast ref={toast} position="top-center"/>
    <div class="formgrid" style={{textAlign:'center'}}>
            <h1 >Contact us</h1>
    </div>

    <div class="formgrid " style={{textAlign:'center'}}>
        Please fill out the form below for queries related to our app and website
    </div>

    <div className="formgrid grid hidden-xs" style={{ marginLeft: '15rem', padding: '14em', paddingTop: 0, paddingBottom: 0, marginRight: '15rem', marginTop: "40px", textAlign: 'left' }}>
            <div className="field col-12 md:col-6">
                <label htmlFor="firstname6">Firstname</label>
                <input
                    id="firstname6"
                    type="text"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="lastname6">Lastname</label>
                <input
                    id="lastname6"
                    type="text"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />
            </div>
            <div className="field col-12">
                <label className='textLabel' htmlFor="email">Email</label>
                <input
                    id="email"
                    type="text"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="field col-12">
                <label htmlFor="message">Message</label>
                <textarea
                    id="message"
                    rows="4"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
            </div>
            <div className="field col-12">
                <Button className='w-full' label="Submit" onClick={()=> sendEmail()} />
            </div>
        </div>

        <div className="formgrid grid search-mobile-only" style={{padding: '4em', textAlign: 'left' }}>
            <div className="field col-12 md:col-6">
                <label htmlFor="firstname6">Firstname</label>
                <input
                    id="firstname6"
                    type="text"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </div>
            <div className="field col-12 md:col-6">
                <label htmlFor="lastname6">Lastname</label>
                <input
                    id="lastname6"
                    type="text"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />
            </div>
            <div className="field col-12">
                <label className='textLabel' htmlFor="email">Email</label>
                <input
                    id="email"
                    type="text"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="field col-12">
                <label htmlFor="message">Message</label>
                <textarea
                    id="message"
                    rows="4"
                    className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
            </div>
            <div className="field col-12">
                <Button className='w-full' label="Submit" onClick={()=> sendEmail()} />
            </div>
        </div>
        <AppFooter />
    </div>
     )
}

export default ContactUs
