import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { ProductService } from "../../Service/ProductSerivice";
import { Tag } from "primereact/tag";
import { Chip } from "primereact/chip";
import "./Product.css";
import { Avatar } from "primereact/avatar";
import { Skeleton } from "primereact/skeleton";
import { Chart } from "primereact/chart";
import ProductPriceHistoryChart from "../../libs/ProductPriceHistoryChart";
import GridProducts from "../Shared/GridProducts";
import { Message } from 'primereact/message'
import { Toast } from "primereact/toast";
import { Login } from "../Authentication/Login";
import { AppFooter } from "../../Modules";

export default function Product(props) {
  const [productItem, setProductItem] = useState(null);
  const [tracked, setTracked] = useState(false);
  const [similarPromoProducts, setSimilarPromoProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [otherStoresProducts, setOtherStoresProducts] = useState(null);
  const [user,] = useState(JSON.parse(localStorage.getItem('user')));
  const [priceHistory, setPriceHistory] = useState("");
  const [searchParams] = useSearchParams();
  const [showLogin, setShowLogin] = useState(false);
  const productCode = searchParams.get("code");
  const title = document.getElementById("app-title");
  const toastBottomCenter = useRef(null);
  const [isDefinately, setIsDefinately] = useState(false);
  const [isYes, setIsYes] = useState(false);
  const [isNo, setIsNo] = useState(false);

  const fetchData = async () => {

    setIsLoading(true);
    try {
      if (!productCode) {
        throw new Error("Product code is missing");
      }

      const priceHistory = await ProductService.getProductPriceHistory(productCode);

      setPriceHistory(priceHistory);

      const productData = await ProductService.getSingleProduct(productCode);
      setProductItem(productData);

      if(user){
        const tracked = await ProductService.GetIsTracked(productCode, user.id);
        setTracked(tracked);
      }

      var updatedSearchTerm = "";
      
      if(productData.categoryNames.includes(';'))
          productData.categoryNames  = productData.categoryNames.replace(/;/g,',');

      if(productData.categoryNames.includes('&'))
          productData.categoryNames  = productData.categoryNames.replace(/&/g,',');
        
      updatedSearchTerm = (productData.categoryNames.split(',').reverse()[0] + ',' + productData.categoryNames.split(',')?.reverse()[1]).toString();

      const similarProductsResponse = await ProductService.getSimilarProducts(updatedSearchTerm);
      setSearchTerm(updatedSearchTerm);
      setSimilarPromoProducts(similarProductsResponse.items.splice(0, 6));

      const otherStores = await ProductService.getOtherStoreProducts(productData.productCode);

      setOtherStoresProducts(otherStores || null);

      // Set promotion details
      if (productData.percentageSaved > 9) {
        setIsDefinately(true);
        setIsNo(false)
        setIsYes(false);
      } else if (productData.percentageSaved > 0 && productData.percentageSaved < 10) {
        setIsYes(true);
        setIsDefinately(false);
        setIsNo(false)
      } else if (productData.percentageSaved < 1) {
        setIsNo(true);
        setIsYes(false);
        setIsDefinately(false);
      }
      else
      {
        setIsDefinately(false);
        setIsNo(false);
        setIsNo(false)
      }

      title.innerHTML = productData.name;
    } catch (error) {
      console.error("Error loading product", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); 

    fetchData();
  }, [productCode,searchTerm]); // Fetch data when product code changes

  const itemTemplate = () => {

    const TrackProduct = (val) => {

      if(!user)
      {
      //   let user = {
      //     "fullName": "Thabang Lekwadi",
      //     "id": "AwAAAA==",
      //     "email": null,
      //     "code": null,
      //     "isVerified": false,
      //     "dateCreated": "0001-01-01T00:00:00"
      // }
      // localStorage.setItem("user",JSON.stringify(user))
      setShowLogin(true)
      return;
      }
      
      ProductService.SetProductAlert(productCode, user.id)
        .then((response) => {
          setTracked(val);
          showMessage(val,toastBottomCenter, val ? 'success' : 'warn');
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const showMessage = (event, ref, severity) => {
      const label = event ? "Product Tracked" : "Product Untracked";

      ref.current.show({ severity: severity, detail: label, life: 3000 });
  };

  const convertDate = (date) => {
    // const inputDateStr = "2024-04-21T23:59:59";

    const inputDate = new Date(date);
    const startDateStr = inputDate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return startDateStr;
  };


  const updateProfile =(fullname)=>{
    setShowLogin(false);
     //ReloadPage
     window.location.reload();
  }

  const isOutofStock = (date,partnerImageUrl) => {
    const now = new Date();
    const diffMs = now - new Date(date);
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffMin / 60);


  if (diffHour > 8) {
      if(partnerImageUrl?.includes('shoprite.co.za') ||
        partnerImageUrl?.includes('www.checkers.co.za') ||
        partnerImageUrl?.includes('www.woolworths.co.za'))
       {
          return true;
       }

      return false
    }
    else
      return true;
  };

  const timeAgo = (date) => {
    const now = new Date();
    const diffMs = now - new Date(date);
    const diffSec = Math.round(diffMs / 1000);
    const diffMin = Math.floor(diffSec / 60);
    const diffHour = Math.floor(diffMin / 60);
    const diffDay = Math.floor(diffHour / 24);

    if (diffSec < 60) {
      return `${diffSec} seconds ago`;
    } else if (diffMin < 60) {
      return `${diffMin} minutes ago`;
    } else if (diffHour < 24) {
      return `${diffHour} hours ago`;
    } else {
      return `${diffDay} days ago`;
    }
  };

  const DecodedText =(productItem) => {
    const encodedText = productItem;
  
    // Create a temporary element
    const tempElement = document.createElement('div');
    tempElement.innerHTML = encodedText;
  
    // Get the decoded text
    const decodedText = tempElement.innerText;
  
    return (
        <span>{decodedText}</span>
    );
  }

    return (
      <>
        {!isLoading ? (
          <>
            <div
              className="grid screen-margin"
              style={{ margin: props.spacing }}
            >
              {<Login updateProfile = {updateProfile} show={showLogin} showLogin={ () => setShowLogin(false)} />}
          <Toast ref={toastBottomCenter} position="bottom-center" />
              {/* Left (Product) */}
              <div className="col-12 md:col-4 lg:col-4">
                <div className="flex flex-wrap align-items-center justify-content-center border-1 border-black-alpha-10">
                  <div className="pt-5 w-12rem m-3 border-round">
                  <img
                      className="w-9  flex align-items-left justify-content-left ml-2"
                      src={productItem.partnerImageUrl}
                      alt={productItem.name}
                    />
                    <div className="border-round-bottom p-3 flex align-items-center justify-content-center">
                      <img
                        className="w-9 sm:w-16rem xl:w-10rem  xl:block mx-auto border-round fullsize-image"
                        src={`${productItem.imageUrl}`}
                        alt={productItem.name}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Right side (productItem Details) */}
              <div className="col-12 md:col-8 lg:col-8">
                <div className="text-left p-0 ">
                  <span className="text-400 surface-overlay font-medium text-xs">
                    <i className="pi pi-tag" style={{ fontSize: ".7rem" }}></i>{" "}
                    {DecodedText(productItem.categoryNames)}
                  </span>
                </div>

                <div className="text-left p-0 ">
                  <div className="text-2xl font-bold">
                    <span>{DecodedText(productItem.name)}</span>
                  </div>
                </div>

                {/* If productItem on promotion */}
                {productItem.promoEndDate && (
                  <div className="text-left p-0 space">
                    <span className="text-xs">
                      <Avatar
                        label={`${productItem.percentageSaved}%`}
                        size="small"
                        className={productItem.percentageSaved > 0 ? "bg-primary" : "bg-red-600"}
                        style={{
                          fontSize: ".75rem",
                          fontWeight: "700",
                          color: "#ffffff",
                          height: "20px",
                          marginRight: "3px",
                          borderRadius: "2px",
                        }}
                      />
                      ({productItem.saving} saving)
                    </span>
                  </div>
                )}

                <div className="text-left p-0 space">
                  {/* If productItem price dropped */}
                  {!productItem.promoEndDate ? (
                    <span className="text-2xl font-bold">
                      <div className="text-400 text-sm sub-price">
                        {productItem.oldPrice > 0 ? (
                          <span className="text-xs">
                            <Avatar
                              label={`${productItem.percentageSaved}%`}
                              size="small"
                              className={productItem.percentageSaved > 0 ? "bg-primary" : "bg-red-600"}
                              style={{
                                fontSize: ".75rem",
                                fontWeight: "700",
                                color: "#ffffff",
                                height: "20px",
                                marginRight: "3px",
                                borderRadius: "2px",
                              }}
                            />
                            ({productItem.saving} saving)
                          </span>
                        ) : (
                          <i style={{ opacity: "-2" }}> _</i>
                        )}
                      </div>
                      <div className="col-2 m-0 p-0">
                        <div className="flex justify-content-between">
                          R{productItem.price}

                          {productItem.oldPrice > 0 && (
                            <div className="text-sm text-500 old-price">
                              <del>R{productItem.oldPrice}</del>
                            </div>
                          )}
                        </div>
                      </div>
                    </span>
                  ) : (
                    <>
                      <span className="text-2xl font-bold">
                        {productItem.promoDescription}
                      </span>
                      <br />
                      <strong>R{productItem.promoPrice}</strong>
                      <span className="text-sm text-500 old-price">
                        <del>R{productItem.price}</del>
                      </span>
                    </>
                  )}

                  <p className="text-500 surface-overlay font-medium text-xs font-italic">
                        <i className="pi pi-clock" style={{ fontSize: '.8rem' }}> </i> Price updated {timeAgo(productItem.dateUpdated)}
                    </p>
                
                  {!isOutofStock(productItem.dateUpdated,productItem.imageUrl) && <p><Message icon="pi pi-ban" severity="error" text="Out of stock" /> </p>}
                  
                  {productItem.promoEndDate && (
                    <p className="text-500 surface-overlay font-medium text-xs font-italic">
                      Valid from {convertDate(productItem.promoStartDate)} until {convertDate(productItem.promoEndDate)}
                    </p>
                  )}
                </div>

                <div className="text-center p-3 border-round-sm surface-50 font-bold">
                  <div className="p-0 space">
                    <div className="text-sm  text-center">
                      Should i purchase this product right now?
                    </div>

                    <div className="text-sm  text-center space">
                      <Tag
                        style={{ width: "40%", borderRadius: "0px" }}
                        severity="success"
                        value="Definitely"
                        className={isDefinately ? "text-white-alpha-90 font-bold" : "text-black-alpha-90"}
                      >
                        {isDefinately && (
                          <i
                            className="pi pi-check"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        )}
                      </Tag>

                      <Tag
                        style={{ width: "30%", borderRadius: "0px" }}
                        severity="warning"
                        value="Yes"
                        className={isYes ? "text-white-alpha-90 font-bold" : "text-black-alpha-90"}
                      >
                        {isYes && (
                          <i
                            className="pi pi-check font-bold"
                            style={{ marginLeft: "19px" }}
                          ></i>
                        )}
                      </Tag>
                      <Tag
                        style={{ width: "30%", borderRadius: "0px" }}
                        severity="danger"
                        value="Wait"
                        className={isNo ? "text-white-alpha-90 font-bold" : "text-black-alpha-90"}
                      >
                        {isNo && (
                          <i
                            className="pi pi-check font-bold"
                            style={{ marginLeft: "10px" }}
                          ></i>
                        )}
                      </Tag>
                    </div>
                  </div>

                  <div className="text-600 text-sm  text-left space">
                    {isDefinately &&
                      `Take advantage of the limited-time offer of ${productItem.percentageSaved} % off from original price and secure your product before the promotion ends. `}

                    {isYes &&
                      `You save ${productItem.percentageSaved}% by purchasing it now, and we highly recommend taking advantage of this offer. `}

                    {isNo &&
                      `We advice holding off on purchasing this product to benefit from upcoming promotions. `}

                    <h4 className="text-100 font-bold text-900">
                    productItem Details:
                    </h4>
                    <p style={{ fontStyle: "italic" }}>{productItem.description}</p>
                    {/* <Chip
                      label={`Quantity (${product.stock.stockLevel})`}
                      icon="pi pi-cart-plus"
                    /> */}
                  </div>
                </div>

                <div className="text-left p-0 space">
                  {tracked ? (
                    <Button
                      label="Untrack Product"
                      severity="danger"
                      outlined
                      onClick={(_) => TrackProduct(false)}
                    />
                  ) : (
                    <Button
                      label="Track Product"
                      severity="help"
                      outlined
                      icon="pi pi-bell"
                      iconPos="right"
                      onClick={(_) => TrackProduct(true)}
                    />
                  )}
                </div>
              </div>

              {/* Chart Price history  */}
              <div className="col-12">
                <ProductPriceHistoryChart priceHistory={priceHistory}/>
              </div>
            </div>

            {/* Other similar promotions */}
            {otherStoresProducts && otherStoresProducts.length > 0 &&  <div className="col-12">
              <GridProducts
                isLoading={isLoading}
                header={"Other Stores"}
                items={otherStoresProducts}
              />
            </div>}

            <div className="col-12">
              <GridProducts
                isLoading={isLoading}
                header={"Similar Items"}
                viewAll={"/search-product?similar=true&q=" + encodeURIComponent(searchTerm)}
                viewAllHeader={"View All"}
                items={similarPromoProducts}
              />
            </div>
            <AppFooter/>
          </>
        ) : (
          <div className="grid screen-margin" style={{ margin: props.spacing }}>
            {/* Left (Product) */}
            <div className="col-12 md:col-4 lg:col-4">
              <div className="flex flex-wrap align-items-center justify-content-center border-1 border-black-alpha-10">
                <div className="pt-5 w-12rem m-3 border-round">
                  <div className="border-round-bottom p-3 flex align-items-center justify-content-center">
                    <Skeleton
                      height="15rem"
                      className="w-9 sm:w-16rem xl:w-10rem  xl:block mx-auto border-round fullsize-image"
                    ></Skeleton>
                  </div>
                </div>
              </div>
            </div>

            {/* Right side (Product Details) */}
            <div className="col-12 md:col-8 lg:col-8">
              <div className="text-left p-0 ">
                <span className="text-400 surface-overlay font-medium text-xs">
                  <Skeleton width="5rem" className="mb-2"></Skeleton>
                </span>
              </div>

              <div className="text-left p-0 ">
                <div className="text-2xl font-bold">
                  <Skeleton width="10rem" className="mb-2"></Skeleton>
                </div>
              </div>

              <div className="text-left p-0 space">
                <Skeleton width="15rem" className="mb-2"></Skeleton>
              </div>

              <div className="text-left p-0 space">
                <Skeleton
                  width="10rem"
                  height="3rem"
                  className="mb-2"
                ></Skeleton>
              </div>

              <div className="text-center p-3 border-round-sm surface-50 font-bold">
                <div className="p-0 space">
                  <Skeleton
                    width="100%"
                    height="5rem"
                    className="mb-2"
                  ></Skeleton>
                </div>

                <div className="text-400 text-sm  text-left space">
                  <Skeleton width="15rem" className="mb-2"></Skeleton>
                </div>
                <div className="text-400 text-sm  text-left space">
                  <Skeleton
                    width="10rem"
                    height="2rem"
                    className="mb-2"
                  ></Skeleton>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  return <>{productItem && itemTemplate(productItem, 0)}</>;
}
