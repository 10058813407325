import React, { useEffect, useState } from "react";

import { Promotional, PriceDrop,AppFooter } from "../../Modules";

export function Products() {
  const [spacing] = useState("1rem");
  const title = document.getElementById("app-title");

  useEffect(() => {
    title.innerHTML = "XaPrice Home";
  });

  return (
    <>
      {/* <Product spacing={spacing} /> */}
      <div className="-mt-2">
           <Promotional  spacing={spacing} stopLazyload={true} header="Promotions" viewAll={"/on-promotion"} />
            <div style={{marginTop:'3rem'}}></div>
           <PriceDrop  spacing={spacing} stopLazyloadDrop={true} header="Price drops" viewAll={"/price-drop"} />
            
      </div>
    </>
  );
}
