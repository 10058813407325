import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import React, {  } from "react";
import './AppFooter.css'

export default function AppFooter(props) {
  return (
<footer className="card p-p-4 p-shadow-4 screen-margin grid-background" style={{ margin: props.spacing, marginTop: ""}}>

<div class="grid " style={{margin:props.spacing}}>
    <div class="col-12">
        <Divider/>  
    </div>
    <div class="col-12 md:col-6 lg:col-5">
    <div class="text-left p-3 border-round-sm font-bold">
    <h2 style={{ cursor: "pointer" }}>
      <i className="pi pi-wallet text-2xl"></i> XaPrice
    </h2>
    
        <p className="p-text">
        XaPrice is a free tool to check Price History charts for millions of products. With our process of capturing latest specials and promotionals, you can monitor prices for popular South African stores such as Pick n Pay, Checkers, Woolworths and many more.
        </p>
        {/* <div>
            <a href="https://instagram.com/yourcompany" className="p-mr-2">
                <i className="pi pi-instagram"></i>
            </a>
            <a href="https://twitter.com/yourcompany" className="p-mr-2">
                <i className="pi pi-twitter"></i>
            </a>
            <a href="https://facebook.com/yourcompany" className="p-mr-2">
                <i className="pi pi-facebook"></i>
            </a>
            <a href="https://linkedin.com/yourcompany" className="p-mr-2">
                <i className="pi pi-linkedin"></i>
            </a>
            <a href="https://telegram.com/yourcompany" className="p-mr-2">
                <i className="pi pi-telegram"></i>
            </a>
        </div> */}
        </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
        <div class="text-center p-3 border-round-sm font-bold">
        <h4>XAPRICE</h4>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {/* <li><a href="/features">Features</a></li> */}
            {/* <li className="li-text"><a className="p-text" href="/download">Download</a></li> */}
            <li className="li-text"><a className="p-text" href="/supported-stores">Supported Stores</a></li>
        </ul>
        </div>
    </div>
    {/* <div class="col-12 md:col-6 lg:col-3">
        <div class="text-center p-3 border-round-sm font-bold">
        <h4>FOR BUSINESSES</h4>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li><a href="/api">API</a></li>
        </ul>
        </div>
    </div> */}
    <div class="col-12 md:col-6 lg:col-3">
        <div class="text-center p-3 border-round-sm font-bold">
        <h4>COMPANY</h4>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li className="li-text"><a className="p-text" href="/terms-and-conditions">Terms & Conditions</a></li>
            <li className="li-text"><a className="p-text" href="/privacy-policy">Privacy Policy</a></li>
            <li className="li-text"><a className="p-text" href="/disclaimer">Disclaimer</a></li>
            <li className="li-text"><a className="p-text" href="/contact-us">Contact Us</a></li>
        </ul>
        </div>
    </div>
</div>

<div className="p-mt-12"style={{marginBottom:'2rem'}}>
    <Divider/>
    <div className="p-grid text-500 text-xs font-semibold" >
        <div className="p-col-12 p-md-6 p-text-left">
            <p style={{textAlign:'center'}}>&copy; 2024 XaPrice All rights reserved.</p>
        </div>
        <div className="p-col-12 p-md-6 p-text-left text-xs ">
            <p>We use cookies to offer better online experiences. By visiting and using XaPrice, you consent to our use of cookies. Learn about your options, rights and more by reading our Terms & Conditions and Privacy Policy.</p>
        </div>
    </div>
</div>
</footer>
  );
}
