import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { ProductService } from "../../Service/ProductSerivice";
import "./PriceDrop.css";
import GridProducts from "../Shared/GridProducts";


export default function PriceDrop(props) {
  const [priceDropProducts, setPriceDropProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [priceDropText, setPriceDropText] = useState(null);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12); // Initial page size

  const containerRef = useRef(null);
  const title = document.getElementById("app-title");

  useEffect(() => {
    const fetchData = async () => {
      let fullSize = false;

      if (location.pathname === "/price-drop") {
        setPriceDropText("Price drop");
        title.innerHTML = "Price Drops";

        fullSize = true;
      }

      if (fullSize) {
        try {
          // Get Summary of Promotions like in landing pages
          const priceDrops = await ProductService.getGetAllPriceDrops(
            currentPage,
            pageSize
          );

          setPriceDropProducts((prevProducts) => [
            ...prevProducts,
            ...priceDrops,
          ]);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching promotional products", error);
          setIsLoading(false);
        }
      } else {
        // Get Summary of Promotions like in landing pages
        const priceDrops = await ProductService.getGetAllPriceDrops(1,6);

        // Process these items and pick 10
        setPriceDropProducts(priceDrops);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, pageSize]);

  // Load more items when scrolling to almost the bottom
  const handleScroll = () => {
    
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    const scrollPositionToBottom = scrollHeight - (scrollTop + window.innerHeight);
    const loadThreshold = 200; // Adjust this threshold as needed

    if (scrollPositionToBottom < loadThreshold) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if(!props.stopLazyloadDrop)
    {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };

    }
  }, []);

  return (
    <div ref={containerRef} className="scroll-container">
      <GridProducts
        viewAllHeader={'View all Price Drops'}
        isLoading={isLoading}
        header={priceDropText || props?.header}
        icon = {<i className="pi pi-angle-double-down text-400" style={{ fontSize: '1.1rem' }}></i>}
        spacing={props.spacing}
        viewAll={props.viewAll}
        items={priceDropProducts}
      />
    </div>
  );
}
