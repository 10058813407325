import axios from 'axios';
const prod = "https://api.xaprice.co.za";
const local = "https://localhost:5001";
const URL_XAPRICE = prod + "/api/Products";
const URL_XAPRICE_LOCAL = local + "/api/Products";
const URL_XAPRICE_ANALYTICS = prod + "/api/Analytics";

export const ProductService = {
   
    getSingleProduct(code){
        return axios.get(URL_XAPRICE + '/GetProductByCode?code=' + code).then(res => res.data);
    },
    getAllProductAlert(userId){
        return axios.get(URL_XAPRICE + '/GetAllProductAlert?userId=' + userId).then(res => res.data);
    },
    
    getSingleProductXaprice(code){
        return axios.get(URL_XAPRICE + '/GetProductByCode?code='+  code).then(res => res.data);
    },

    addProductXaprice(product){
        return axios.post(URL_XAPRICE + '',  product).then(res => res.data);
    },


    SetProductAlert(code, userId){
        return axios.post(URL_XAPRICE + `/SetProductAlert?productCode=${code}&userId=${userId}`).then(res => res.data);
    },


    GetIsTracked(code, userId){
        return axios.get(URL_XAPRICE + `/GetIsTracked?productCode=${code}&userId=${userId}`).then(res => res.data);
    },

    //Pagination on our API
    getPromotionalProducts(currentPage = 1, pageSize = 24){
        return axios.get(URL_XAPRICE +`/GetAllPromotions?page=${currentPage}&pageSize=${pageSize}`).then(res => res.data);
    },

    getPromotionalProducts(currentPage = 1, pageSize = 24){
        return axios.get(URL_XAPRICE +`/GetAllPromotions?page=${currentPage}&pageSize=${pageSize}`).then(res => res.data);
    },

    getLatestPromotions(currentPage = 1, pageSize = 24){
        return axios.get(URL_XAPRICE + '/GetLatestPromotions').then(res => res.data);
    },
    getSimilarProducts(searchTerm, currentPage = 1,pageSize = 24){
        return axios.get(URL_XAPRICE +`/GetSimilarProducts?searchTerm=${searchTerm}&page=${currentPage}&pageSize=${pageSize}`).then(res => res.data);
    },

    getOtherStoreProducts(productCode){
        return axios.get(URL_XAPRICE +`/GetOtherStoreProducts?productCode=${productCode}`).then(res => res.data);
    },

    getGetAllPriceDrops(currentPage = 1,pageSize = 24){
        return axios.get(URL_XAPRICE +`/GetAllPriceDrops?page=${currentPage}&pageSize=${pageSize}`).then(res => res.data);
    },

    getSearchedProduct(searchTerm, currentPage = 1,pageSize = 24){
        return axios.get(URL_XAPRICE +`/Search?searchTerm=${searchTerm}&page=${currentPage}&pageSize=${pageSize}`).then(res => res.data);
    },

    getLatestPromotionsByTag(tag, currentPage = 1,pageSize = 24){
        return axios.get(URL_XAPRICE +`/GetPromotionsByTag?searchTerm=${tag}&page=${currentPage}&pageSize=${pageSize}`).then(res => res.data);
    },

    getProductPriceHistory(productCode){
        return axios.get(URL_XAPRICE_ANALYTICS + `/GetProductPriceHistory?productCode=${productCode}`).then(res => res.data);
    }
};

