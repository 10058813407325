import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect } from "react";
import GridProductItem from "./GridProductItem";
import { useNavigate } from 'react-router-dom';

export default function GridProducts(props) {
  // const navigate = useNavigate();
  const navigate = useNavigate();

  useEffect(() => {
    //Massage the data
  }, []);

  const defaultArray = [];

  for (let i = 1; i <= 6; i++) {
    const object = {
      field: `value${i}a`,
    };
    defaultArray.push(object);
  }

  const navigateTo = (url) => {
    navigate(url);

  };

  return (
    <div className="grid screen-margin" style={{ margin: props.spacing, marginTop: ""}}>
      <div className="col-12">
        <div className="flex justify-content-between">
          <p className="text-2xl font-semibold text-900 mb-1">
            {props.header} {props.icon}
          </p>

          {props?.viewAll && <Button
            label={props.viewAllHeader || "View all Promos"}
            severity="bg-primary"
            className="-mb-4"
            onClick={(_) => navigateTo(props.viewAll)}
            text
            size={'small'}
            icon="pi pi-arrow-right"
            iconPos="right"
          />}
        </div>

      </div>
      {!props.isLoading
        ? props.items?.map((item, index) => (
            <GridProductItem key={index} item={item}/>
          ))
        : defaultArray.map((item, index) => (
            <div key={index} className="col-12 sm:col-4 md:col-3 lg:col-3 xl:col-2 ">
              <div className="border-1 border-black-alpha-30">
                <div className="flex flex-wrap align-items-center justify-content-center mb-2 m-2">
                  {/* Image section */}
                  <Skeleton width="100%" height="150px"></Skeleton>
                </div>

                {/* Bottom section  */}
                <div className="m-2">
                  <Skeleton width="8rem" className="mb-2"></Skeleton>
                  <Skeleton
                    width="6rem"
                    height="2rem"
                    className="mb-2"
                  ></Skeleton>
                  <Skeleton width="5rem" className="mb-2"></Skeleton>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
}
