import React, { useState } from 'react'
import { Card } from 'primereact/card';
import './SupportedStores.css'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { AppFooter } from '../../Modules';

function SupportedStores() {

    const [stores, setStores] = useState([{
        name: 'Pick n Pay Stores',
        website: 'https://www.pnp.co.za',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Pick_n_Pay_logo.svg/1280px-Pick_n_Pay_logo.svg.png',
    },
    {
        name: 'Checkers',
        website: 'https://www.checkers.co.za',
        image: 'https://www.checkers.co.za/medias/checkers-logo.svg?context=bWFzdGVyfGltYWdlc3w2NTY2fGltYWdlL3N2Zyt4bWx8aW1hZ2VzL2g5ZS9oN2MvODg2NTY1MDgwMjcxOC5zdmd8MTQwMWY4NTIwMmI0NDZhM2RhMTI3MTYxYzE2ZDkwOWMyNDUwMDYyYjRjZTkwOTE5NmM2NWI1OGM4OGVjOWI2Yg',
    }]);
    const [layout, setLayout] = useState('grid');

    const gridItem = (store) => {
        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={store.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <img className="w-4 border-round" height='20rem' src={`${store.image}`} alt={store.name} />
                        <div className="text-sm font-bold">{store.name}</div>
                        <a href={store.website} className="" target='_blank' >{store.website} </a>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (store, layout, index) => {
        return gridItem(store);
    };

    const listTemplate = (stores, layout) => {
        return <div className="grid grid-nogutter">{stores.map((store, index) => itemTemplate(store, layout, index))}</div>;
    };

    return (
        <div >
           <div class="formgrid" style={{textAlign:'center'}}>
            <h1 >Supported Stores</h1>
    </div>

    <div class="formgrid " style={{textAlign:'center'}}>
    These stores are supported for your country
    </div>

        {/* For mobile */}
    <div className="card search-mobile-only" >
            <DataView value={stores} listTemplate={listTemplate} layout={layout} />
        </div>

        {/*For desktop */}
        <div className="card hidden-xs" style={{padding:'6rem',marginLeft:'17rem'}} >
            <DataView value={stores} listTemplate={listTemplate} layout={layout} />
        </div>
        <AppFooter />
        </div>
    )
}

export default SupportedStores
