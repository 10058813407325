import React, { useEffect, useRef, useState } from "react";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import "./Appbar.css";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AutoComplete } from "primereact/autocomplete";
import GridProductItem from "../../GridProductItem";
import SearchGridProductItem from "../../SearchGridProductItem";
import { Skeleton } from "primereact/skeleton";
import { Divider } from "primereact/divider";
import { ProductService } from "../../../../Service/ProductSerivice";
import { Login } from "../../../Authentication/Login";
import { useLocalStorage } from "primereact/hooks";
import { Menu } from 'primereact/menu';

// TODO Auto Suggestion
export function Appbar(props) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [profile, setProfile] = useState("Login");
  const [showLogin, setShowLogin] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastItem, setLastItem] = useState(false);
  const [searchParams] = useSearchParams();
  const isNavbar = searchParams.get("navbar");
  const title = document.getElementById("app-title");
  const [user,setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isLoggedIn,setIsLoggedIn] = useState(false);
  const location = useLocation();
  const menuRight = useRef(null);
  const tabviewItems = [
    {
      index: 0,
    },
    {
      index: 1,
      title: (
        <div className="product-category">
          <i className="material-symbols-outlined">liquor</i>
          <span>Alcohol</span>
        </div>
      ),
      searchTerm: "liquor,Spirit-Coolers",
    },
    {
      index: 2,
      title: (
        <div className="product-category">
          <i className="material-symbols-outlined">child_care</i>
          <span>Baby</span>
        </div>
      ),
      searchTerm: "Baby",
    },
    {
      index: 3,
      title: (
        <div className="product-category">
          <i className="material-symbols-outlined">nutrition</i>
          <span>Fruit & Veg</span>
        </div>
      ),
      searchTerm: "Fresh Fruit,Fresh Vegetables",
      // Fresh Fruit
    },
    {
      index: 4,
      title: (
        <div className="product-category">
          <i className="material-symbols-outlined">health_and_beauty</i>
          <span>Personal Care</span>
        </div>
      ),
      searchTerm: "Personal Care & Health",
    },
    {
      index: 5,
      title: (
        <div className="product-category">
          <i className="material-symbols-outlined">cable</i>
          <span>Appliances</span>
        </div>
      ),
      searchTerm: "Office,Appliances & Outdoor",
    },    {
      index: 6,
      title: (
        <div className="product-category">
          <i className="material-symbols-outlined">cleaning_services</i>
          <span>Household</span>
        </div>
      ),
      searchTerm: "household,Household & Cleaning",
    },
  ];


  const goBack = () => {
    navigate(-1); // Go back one step in history
  };

  useEffect(() => {

    if(user !== null)
    {
      var fullname = user.fullName.split(' ')[0];
      setProfile(fullname);
      setIsLoggedIn(true);
    };
    
    if (isNavbar !== "true") setActiveIndex(-1);
  }, [searchParams]);

  const updateProfile =(fullname)=>{
    setProfile(fullname);
    setShowLogin(false);
    window.location.reload();
  }

  const logout =()=>{
    localStorage.setItem('user',null);
    setProfile("Profile");
    setIsLoggedIn(false);
    setShowLogin(false);
    
    window.location.reload();

  }

  const navigateTo = (e) => {
    navigate(
      `/search-product?q=${encodeURIComponent(
        tabviewItems[e].searchTerm
      )}&navbar=true`
    );
    setActiveIndex(tabviewItems[e].index);
  };

  const goToPage =(e) =>{
    navigate(e);
  }
  const navigateToHome = () => {
    navigate(`/`);
  };

  const Profileitems = [
    {
        label: 'Profile',
        items: [
            {
                label: 'Account',
                icon: 'pi pi-cog'
            },
            {
              label: 'Alerts',
              icon: 'pi pi-bell',
              command: () => {
                goToPage('/product-alerts');
              }
            },
            {
                label: 'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                  logout();
              }
            }
        ]
    }
];

  const items = [
    // {
    //   label: "Home",
    //   icon: "pi pi-home",
    // //   template: itemRenderer
    // },
  ];
  const validate = () => {
    if (searchTerm.length <= 0 || searchTerm == "") {
      alert("Please enter text");
    } else {
      navigate(`/search-product?q=${searchTerm}`);
    }
  };
  const start = (
    <h2 onClick={() => navigateToHome()} style={{ cursor: "pointer" }}>
      <i className="pi pi-wallet text-2xl"></i> XaPrice
    </h2>
  );

  const handleKeyDown = (event) => {
    console.log("key", event.key)
    if (event.key === "Enter") {
      navigate(`/search-product?q=${searchTerm}`);
    }
  };

  // AUTO SUGGESTED
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredCities, setFilteredCities] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const searchDisplayTemplate = (item) => {
    return (
      <div>
        <div className="text-sm font-bold"
        >
          {item.name}
        </div>
        <hr className="hr-red" />
      </div>
    );
  };

  const onChange = async (e) => {
    setSelectedProduct(e.value);
    setSearchTerm(e.value);
  };

  const redirect =(e) =>{
    navigate(`/product?code=${e.value.productCode}`)
    setSearchTerm("");
    setSelectedProduct("");
  }

  const search = async (event) => {
    let query = event.query;
    let data = await ProductService.getSearchedProduct(query, 1, 6);

    setFilteredProducts(data.items);
  };


  const hangleProfileClick = async (event) => {
    setShowLogin(true);
  };


  const end = (
    <div className="flex align-items-center gap-2">
      <div className="p-inputgroup flex-1 w-full md:w-30rem h-1.5rem mr-3 hidden-xs">
        <AutoComplete
          value={selectedProduct}
          onChange={(e) => onChange(e)}
          onKeyDown={handleKeyDown}
          suggestions={filteredProducts}
          completeMethod={search} 
          // minLength={3}
          field="name"
          scrollHeight={"100%"}
          itemTemplate={searchDisplayTemplate}
          placeholder="Search product name or category"
          onSelect={redirect}
        />

        <Button
          icon="pi pi-search"
          severity="secondary"
          onClick={validate}
          raised
        />
      </div>
      {!isLoggedIn && <span style={{cursor:'pointer'}} onClick={() => hangleProfileClick()}>{profile}</span>}
      
      {isLoggedIn && <> <span onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup>{profile}</span>
      <Avatar
        image="xaprice_account.png"
        shape="circle"
        // style={{cursor:'pointer'}} onClick={() => hangleProfileClick()}
        onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup
      />
      <Menu model={Profileitems} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" className="w-full md:w-10rem mt-2" />
      </>}
    </div>
  );

  return (
    <div
      className="card bg-primary"
      style={{ position: "sticky", top: "0", zIndex: "1000" }}
    >
      {/* <div className="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
    <div className="font-bold mr-8">🔥 Hot Deals!</div>
    <div className="align-items-center hidden lg:flex">
        <span className="line-height-3">Libero voluptatum atque exercitationem praesentium provident odit.</span>
    </div>
    <a className="flex align-items-center ml-2 mr-8">
        <span className="underline font-bold">Learn More</span>
    </a>
    <a className="flex align-items-center no-underline justify-content-center border-circle text-100 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150" style={{ width: '2rem', height: '2rem' }}>
        <i className="pi pi-times"></i>
    </a>
</div> */}
      <Menubar
        model={items}
        className="screen-margin bg-primary"
        start={start}
        end={end}
        style={{
          backgroundColor: "bg-primary",
          margin: props.spacing,
          border: "0",
          marginBottom: 0,
          marginTop: 0,
          height: "4rem",
        }}
      />

      <div className="p-inputgroup flex-1 w-full md:w-30rem h-1.5rem search-mobile-only">
        {/* <InputText
          placeholder="Search product name"
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
        /> */}

        <AutoComplete
          value={selectedProduct}
          onChange={(e) => onChange(e)}
          suggestions={filteredProducts}
          onKeyDown={handleKeyDown}
          completeMethod={search} 
          // minLength={3}
          field="name"
          scrollHeight={"100%"}
          itemTemplate={searchDisplayTemplate}
          placeholder="Search product name or category"
          onSelect={redirect}
          className={'flex-1 w-full'}
        />

        <Button
          icon="pi pi-search"
          severity="secondary"
          onClick={validate}
          raised
        />
      </div>

      <TabView
        scrollable
        activeIndex={activeIndex}
        onTabChange={(e) => navigateTo(e.index)}
        className={"navbar-laptop-below"}
      >
        {tabviewItems.map((tab) => {
          return (
            //Check if You on big screen or small
            <TabPanel
              key={tab.index}
              header={tab.title}
              style={{
                marginLeft: tab.index === 0 ? "-1rem" : "",
                opacity: tab.index === 0 ? -1 : 1,
              }}
            ></TabPanel>
          );
        })}
      </TabView>

      <TabView
        scrollable
        activeIndex={activeIndex}
        onTabChange={(e) => navigateTo(e.index)}
        className={"navbar-monitor-above"}
      >
        {tabviewItems.map((tab) => {
          return (
            //Check if You on big screen or small
            <TabPanel
              key={tab.index}
              header={tab.title}
              style={{
                marginLeft: tab.index === 0 ? "12rem" : "",
                opacity: tab.index === 0 ? -1 : 1,
              }}
            ></TabPanel>
          );
        })}
      </TabView>

      {/* <Button label="Login" icon="pi pi-user" onClick={() => setVisible(true)} /> */}

        {/* {showLogin && <Login updateProfile = {updateProfile} show={showLogin} isLoggedIn={isLoggedIn} logout={logout}/>} */}
        {<Login updateProfile = {updateProfile} show={showLogin} showLogin={ () => setShowLogin(false)} isLoggedIn={isLoggedIn} logout={logout}/>}


      {location.pathname.length > 1 && location.pathname !== '/product-alerts'&& location.pathname !== '/supported-stores' && location.pathname !== '/contact-us' && location.pathname !== '/terms-and-conditions' && location.pathname !== '/privacy-policy' && location.pathname !== '/disclaimer' &&(
        <div>
          {/* Mobile & laptops*/}
          <div
            className="flex flex-wrap align-items-left justify-content-left"
            style={{ backgroundColor: "white", borderColor: "red" }}
          >
            <Button
              className={"navbar-laptop-below"}
              style={{
                marginLeft: ".5rem",
                marginTop: "1rem",
                marginBottom: "0rem",
              }}
              label="back"
              severity="bg-primary"
              size={"small"}
              onClick={goBack}
              text
              icon="pi pi-arrow-left"
            />
          </div>

          {/* Monitors & above */}
          <div
            className="flex flex-wrap align-items-left justify-content-left"
            style={{ backgroundColor: "white" }}
          >
            <Button
              className={"navbar-monitor-above"}
              style={{ marginLeft: "14.5rem", marginTop: "1rem" }}
              label="back"
              severity="bg-primary"
              size={"small"}
              onClick={goBack}
              text
              icon="pi pi-arrow-left"
            />
          </div>
        </div>
      )}

      
    </div>
  );
}
