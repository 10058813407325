import React from 'react'
import { AppFooter } from '../../Modules'
import './Disclaimer.css'

function Disclaimer() {
  return (
    <div className='container'>
       <h1>Disclaimer</h1>
        <p>Applicable with our <a href="terms-and-conditions">Terms and Conditions</a> and <a href="privacy-policy">Privacy Policy</a></p>

        <h2>How does XaPrice earn profit?</h2>
        <p>We have ties with brands or merchants for affiliate marketing. We send customers to their website or an app and they give us a commission for each sale. They may also pay us to help grow their brand presence. Some merchants pay for unique customer visits, customer actions such as signups, referrals, etc. This partnership helps us to keep our platform free for our users.</p>

        <h2>How do we calculate discounts?</h2>
        <p>Discounts shown on our site are different than discounts shown on merchant sites because we calculate the discount based on the highest recorded price and not based on Maximum Retail Price (M.R.P.). Most merchant sites calculate their discount based on M.R.P. even if the product price has never hit the M.R.P. In addition, we round off prices and discounts so it's easier to read.</p>

        <h2>Pricing and availability</h2>
        <p>Product prices and availability are accurate as of the date/time indicated and are subject to change. Any price and availability information displayed on the store (i.e. Pick n Pay, Checkers, Woolworths, etc.) at the time of purchase will apply to the purchase of displayed products.</p>

        <h2>Why Maximum Retail Price shown is different?</h2>
        <p>We never show M.R.P. on our platform; instead of M.R.P. we show the highest ever recorded price of a product.</p>

        <h2>Product rating and review counts</h2>
        <p>We cache the rating and the review count of products to decrease the load on our platform. You should always check the updated rating and review count on the merchant's website or app.</p>
    
        <AppFooter />
    </div>

  )
}

export default Disclaimer
