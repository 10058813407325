import React from 'react'
import TermsAndCondition from '../../Components/TermsAndConditions/TermsAndConditions'
import { AppFooter } from '../../Modules'

function TermsAndConditions(props) {
  return (
    <div>
      <TermsAndCondition/>
      {/* <AppFooter /> */}
    </div>
  )
}

export default TermsAndConditions
