import React from 'react'
import { AppFooter } from '../../Modules'
import './TermsAndConditions.css'


function TermsAndCondition(props) {
  return (
    <div class="container" >
    <h1>Terms and Conditions</h1>
    <p>THESE TERMS OF USE (THE “TERMS”) ARE A LEGAL CONTRACT BETWEEN YOU (“YOU,” THE “USER,” OR “YOUR”) AND XAPRICE. THE TERMS EXPLAIN HOW YOU ARE PERMITTED TO USE XAPRICE'S INTERNET PROPERTIES, INCLUDING ITS WEBSITE LOCATED AT <a className='websiteLink' href="https://xaprice.co.za/">https://xaprice.co.za</a> AS WELL AS ALL ASSOCIATED SITES (COLLECTIVELY, THE “SITE”), ANY SOFTWARE THAT XAPRICE PROVIDES TO YOU THAT ALLOWS YOU TO ACCESS THE SERVICES FROM A MOBILE DEVICE (A “MOBILE APPLICATION”), AND ALL RELATED CONTENT, PRODUCTS AND SERVICES AVAILABLE THROUGH THE SITE AND THE MOBILE APPLICATION (COLLECTIVELY ALL OF THE ABOVE, THE “SERVICE”). BY USING THE SERVICE, YOU ARE AGREEING TO ALL OF THE TERMS. IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE SERVICE, OR ANY INFORMATION CONTAINED ON THE SERVICE.</p>

    <p>PLEASE NOTE THAT THESE TERMS CONTAIN AN ARBITRATION PROVISION, INCLUDING A CLASS ACTION WAIVER THAT AFFECTS YOUR RIGHTS. IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT OR CANNOT MAKE ANY OF THE FOREGOING REPRESENTATIONS, YOU ARE NOT PERMITTED TO USE OR RECEIVE ANY SERVICES FROM THE COMPANY</p>

    <h2>1. General Use</h2>
    <p>You must follow any policies made available to you within the Services. It is not advisable to try to access our services using a method other than the instructions that are provided. You may use our Services only as permitted by law, including applicable export and other web security laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.</p>
    <p>Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or from us or otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In our Services content is being fetched from various websites. Using various algorithms and technologies, we are displaying the data about the content, (products, deals and offers) as accurately as we can. From hereby content would refer to the products, deals, advertisements and other services offered by shopping portals and brands included in our search engine. If data about some deals, offers or products is not getting displayed from an e-commerce store included in our search engine inform us about the same and we would promptly address the issue. Our Services display some content that are fetched from online and offline shopping portals and various brands. The content that is being given by the store is the sole responsibility of the entity that makes it available. We may review the content to determine whether the products, deals and services offered fall in our legal policies or not and we may remove or refuse to display content that we reasonably believe violates our policies or the law or our ethical values. But that does not necessarily mean that we review content, so please don't assume that we do.</p>

    <h2>2. Privacy Protection</h2>
    <p>XaPrice's privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that XaPrice can use such data in accordance with our privacy policies.</p>
    <p>We respond to notices of alleged any type of infringement and terminate accounts of repeat infringers according to the process the Copyright Acts.</p>

    <h2>3. About Software in our Services</h2>
    <p>When a Service requires or includes downloadable software, this software may update automatically on your device once a new version or feature is available. Some Services may let you adjust your automatic update settings.</p>
    <p>XaPrice gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by XaPrice as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by XaPrice, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.</p>

    <h2>4. Modifying and Terminating our Services</h2>
    <p>We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether. You can stop using our Services at any time, although we'll be sorry to see you go. We may also stop providing Services to you, or add or create new limits to our Services at any time.</p>
    <p>We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.</p>

    <h2>5. Our Warranties and Disclaimers</h2>
    <p>We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don't promise about our Services.</p>
    <p>Other than as expressly set out in these terms or additional terms, XaPrice does not make any specific promises about the Services. For example, we don't make any commitments about the content/offers within the Services, the specific functions of the Services, or their reliability, availability, or ability to meet your needs. We provide the Services "as is". Some jurisdictions provide for certain warranties, like the implied warranty of merchantability, fitness for a particular purpose and non-infringement. To the extent permitted by law, we exclude all warranties.</p>

    <h2>6. Liability for our Services</h2>
    <p>When permitted by law XaPrice, and XaPrice's suppliers and distributors, will not be responsible for lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages.</p>
    <p>To the extent permitted by law, the total liability of XaPrice, and its suppliers and distributors, for any claims under these terms, including for any implied warranties, is limited to the amount you paid us to use the Services (or, if we choose, to supplying you the Services again). In all cases, XaPrice, and its suppliers and distributors, will not be liable for any loss or damage that is not reasonably foreseeable. We recognize that in some countries, you might have legal rights as a consumer. If you are using the Services for a personal purpose, then nothing in these terms or any additional terms limits any consumer legal rights which may not be waived by contract.</p>

    <h2>7. Business uses of our Services</h2>
    <p>If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify XaPrice and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys fees.</p>

    <h2>8. Reviews, Comments, Communications, and Other Content</h2>
    <p>Visitors may post reviews, comments, photos, and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise impermissible, injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of content. XaPrice reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content.</p>
    <p>If you do post content or submit material, and unless we indicate otherwise, you grant XaPrice a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant XaPrice and sublicensees the right to use the name that you submit in connection with such content, if they choose. You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify XaPrice for all claims resulting from content you supply. XaPrice has the right (but not the obligation) to monitor and edit or remove any activity or content. XaPrice takes no responsibility and assumes no liability for any content posted by you or any third party.</p>

    <h2>9. About these Terms</h2>
    <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We'll post notice of modifications to these terms on this page. We'll post notice of modified additional terms in the applicable Service. Changes will not apply immediately and will become effective no sooner than fifteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service. If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict. These terms control the relationship between XaPrice and you. They do not create any third party beneficiary rights. If you do not comply with these terms, and we don’t take action right away, this does not mean that we are giving up any rights that we may have (such as taking action in the future). If it turns out that a particular term is not enforceable, this will not affect any other terms.</p>

    <h2>10. Copyright Complaints</h2>
    <p>XaPrice respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please follow our Notice and Procedure for Making Claims of Copyright Infringement outlined below.</p>

    <h2>Disclaimer of Warranties and Limitation of Liability</h2>
    <p>THIS SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCT RELATED INFORMATION (INCLUDING SOFTWARE) AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY XaPrice ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. XaPrice MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, PRODUCT RELATED INFORMATION (INCLUDING SOFTWARE) OR SERVICES OR PRICE INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.</p>
    <p>THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, XaPrice DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANT-ABILITY AND FITNESS FOR A PARTICULAR PURPOSE. XaPrice DOES NOT WARRANT THAT THIS SITE; INFORMATION, CONTENT, MATERIALS, PRODUCT RELATED INFORMATION (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE; THEIR SERVERS; OR ELECTRONIC COMMUNICATIONS SENT FROM XaPrice ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. XaPrice WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCT RELATED INFORMATION (INCLUDING SOFTWARE) OR SERVICES OR PRICE INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.</p>
    <AppFooter />
</div>

  )
}

export default TermsAndCondition
