import React from "react";
import AppFooter from "./AppFooter/AppFooter";

export default function NotFound() {
  return (
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>

      <AppFooter/>
    </div>
  );
}